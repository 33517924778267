/**
 * Возвращает тип "сущности"
 * @param {*} entity Сущность тип которой надо определить
 * @returns {string} Тип "сущности", например string, number и т.д
 */
export const getType = entity => {
  const regexp = /^\[object (.*)\]$/
  const _type = ({}).toString.call(entity)
  const [ input, target ] = _type.match(regexp) || []
  return target ? target.toLowerCase() : null
}

/**
 * Синоним для getType для локаничности :)
 * @param {string} type Проверяемый тип
 * @return {boolean}
 */
const _checkType = type => entity => (getType(entity) === type)

export const isString         = _checkType("string")
export const isBoolean        = _checkType("boolean")
export const isArray          = _checkType("array")
export const isObject         = _checkType("object")
export const isNumber         = _checkType("number")
export const isUndefined      = _checkType("undefined")
export const isNull           = _checkType("null")
export const isFunction       = _checkType("function")
export const isAsyncFunction  = _checkType("asyncfunction")
export const isPromise        = _checkType("promise")